@import './_variables'
@import './_mixins'

*
  box-sizing: border-box
  outline: none

html,
body
  height: 100%
  overflow-x: hidden

body
  position: relative
  margin: 0
  font-family: 'Montserrat', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-image: url('../resources/images/orange_shadows.png')
  background-position: center center
  background-repeat: no-repeat
  background-size: cover
  background-color: $main-background-color
  font-size: $main-text-size

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

img
  position: relative
  max-height: 100%
  max-width: 100%

.o-main-page-container
  +flexbox(center, center, column, nowrap)
  position: relative
  padding: $large-space calc($large-space*2)
  overflow-x: hidden
  +breakpoint('<tablet')
    padding: $medium-space $medium-space 0 $medium-space

.o-main-logo-container
  +square(180px)
  +breakpoint('<tablet')
    margin: $medium-space 0
    +square(220px)

.o-main-text-container
  position: relative
  font-size: 1.8em
  +flexbox(center, center, row, nowrap)
  +breakpoint('<tablet')
    flex-direction: column !important
  +breakpoint('<phone')
    font-size: 1em

  &__left-panel
    z-index: 1
    +flexbox(center, flex-start, column, nowrap)
    width: 75%
    margin-right: 25%
    +breakpoint('<tablet')
      align-items: center !important
      text-align: center
      width: 100%
      margin-right: 0

    p
      font-size: 1.1em
      font-weight: 500

    p:last-child
      margin-bottom: 0

  &__right-panel
    z-index: 0
    position: relative
    height: 500px
    +flexbox(center, center, column, nowrap)
    +breakpoint('<tablet')
      display: none

    .o-phone-screen
      position: absolute
      right: -250px
      content: ''
      +rectangle(700px, 600px)
      background-image: url('../resources/images/phone_screen.png')
      background-position: center center
      background-repeat: no-repeat
      background-size: contain
      +breakpoint('<tablet')
        display: none

.c-buttons-container
  +flexbox(center, center, row, nowrap)
  gap: $medium-space
  +breakpoint('<tablet')
    flex-wrap: wrap
    gap: 0

  &__button:first-child
    padding: calc($small-space/2)

  &__button
    +flexbox(center, center, column, nowrap)
    +rectangle(220px, 100px)
