$white: #ffffff
$black: #111111

$main-background-color: #fcce8e
$main-text-color: #584D3D
$divider-color: rgba($black, .14)

$main-text-size: 14px
$main-border-radius: 6px

$small-space: 16px
$medium-space: 32px
$large-space: 64px

$phone-size: 600px
$tablet-size: 992px
$desktop-size: 1200px